import React from 'react'



function CompraFalida() {
  return (
    <>
    <h1>Tu pagon no puedo ser contretaro</h1>
    <p>No se te agregaa ningn pagon <br/><b>gracias</b></p>
    </>
  )
}

export default CompraFalida