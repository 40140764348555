import React, { useState } from "react";
import { useFormik } from "formik";
import { Container, Grid, TextField, Button, Box } from "@mui/material";
import * as Yup from "yup";
import axios from "axios";
import { API } from "../../API";

// Validación de formulario usando Yup
const registrationSchema = Yup.object().shape({
  username: Yup.string().required("El nombre de usuario es obligatorio"),
  password: Yup.string().required("La contraseña es obligatoria"),
});

const Registro = () => {
  const [error, setError] = useState(null);

  const handleRegister = async (values) => {
    try {
      const response = await API.post("user/register", values, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response.data.mensaje);

    } catch (error) {
      console.error("Error al registrar el usuario:", error);
      setError("Error al registrar el usuario");
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      handleRegister(values);
    },
  });

  return (
    <Container>
      <Grid container justifyContent="center" alignContent="center">
        <Grid item xs={12} md={4}>
          <form onSubmit={formik.handleSubmit}>
            <Box mt={2}>
              <TextField
                type="text"
                name="username"
                label="Nombre de Usuario"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <TextField
                type="password"
                name="password"
                label="Contraseña"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
              />
            </Box>
            <Box mt={3}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Registrar
              </Button>
            </Box>
          </form>
          {error && <Box mt={2} color="error.main">{error}</Box>}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Registro;
